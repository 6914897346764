import {launchToast} from "@/js/misc/toastService";
import {ErrorPayload, RecordErrorPayload, RecordStatusPayload} from "@/js/stores/types";
import {has} from "lodash-es";
import {defineStore} from "pinia";
import type {Entries} from "type-fest";

export type UserState = {
  user: App.Models.User | null;
  company: number | null;
  status: string | null;
  error: string | null;
  billingError: string | null;
  errors: Array<{key: string; value: string}>;
  productType: App.Enums.ProductType | null;
};

export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    user: null,
    company: null,
    status: null,
    error: null,
    billingError: null,
    errors: [],
    productType: null,
  }),

  actions: {
    setUser(user: App.Models.User | null) {
      this.user = user;
    },
    setCompany(company: number | null) {
      this.company = company;
    },
    setStatus(status: string | null) {
      if (status != null) {
        this.status = status;
      }
    },
    clearStatus() {
      this.status = null;
    },
    setError(error: string | null) {
      this.error = error;
    },
    pushToErrors(error: ErrorPayload) {
      this.errors.push({key: error.key, value: error.value});
    },
    clearError() {
      this.error = null;
    },
    clearErrors() {
      this.errors = [];
    },
    setBillingError(billingError: string | null) {
      this.billingError = billingError;
    },
    setProductType(productType: App.Enums.ProductType | null) {
      this.productType = productType;
    },

    recordErrors(payload: RecordErrorPayload) {
      this.clearError();
      this.clearErrors();
      this.clearStatus();
      if (payload != null) {
        const type = has(payload, "type") ? payload.type : "full";
        if (has(payload, "data")) {
          let message;
          if (has(payload.data, "anError")) {
            message = payload.data.anError;
          } else if (has(payload.data, "message")) {
            message = payload.data.message;
          } else if (typeof payload.data === "string") {
            message = payload.data;
          } else {
            message = "An Error Occurred";
          }

          if (has(payload.data, "errors")) {
            for (const [key, value] of Object.entries(payload.data.errors) as Entries<Record<string, string>>) {
              this.pushToErrors({key, value});
            }
          }

          if (type === "full") {
            this.setError(message);
          } else {
            launchToast(message, "error");
          }
        }
      }
    },

    recordStatus(payload: RecordStatusPayload) {
      this.clearError();
      this.clearErrors();
      this.clearStatus();
      if (payload != null) {
        const type = has(payload, "type") ? payload.type : "toast";
        if (has(payload, "data")) {
          let message;
          if (has(payload.data, "status")) {
            message = payload.data.status;
          } else if (typeof payload.data === "string") {
            message = payload.data;
          } else {
            message = "Success";
          }
          if (type === "full") {
            this.setStatus(message);
          } else {
            launchToast(message, "success");
          }
        }
      }
    },
  },
});
